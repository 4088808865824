import React from "react"
import styled from "@emotion/styled"
import BasicTable from './basicTable';


const SelectBox = styled.div({
  input: {
    display: 'none',
  },
  'input:checked + label': {
    color: '#fff',
    backgroundColor: '#25A298',
    borderColor: 'black',
    fontWeight: 'bold',
  },
  label: {
    display: 'inline-block',
    fontSize: '1.5rem',
    lineHeight: '1.5',
    margin: '5px 2px 5px 0px',
    padding: '0.3em 1em',
    backgroundColor: '#eee',
    color: '#444',
    borderRadius: '5px',
    cursor: 'pointer',
  }
});

// 簡易チェック
// ノートかデスクトップ:
// 3Dゲーム: 
// Youtube/WebBrowsing/Officeなど軽い作業:
// 電源はコンセントから抜くか？

// ゲーム中消費電力 w 0h
// 軽作業中消費電力 w 0h
// アイドル時消費電力 w 0h
// 1日の消費電力
// 1ヵ月電気代
// 1年電気代
// 5年電気代

const dropDownList = [
  { value: 0,  label: '0時間'  },
  { value: 0.5,  label: '0.5時間'  },
  { value: 1, label: '1時間' },
  { value: 1.5, label: '1.5時間' },
  { value: 2, label: '2時間' },
  { value: 2.5, label: '2.5時間' },
  { value: 3, label: '3時間' },
  { value: 4, label: '4時間' },
  { value: 5, label: '5時間' },
  { value: 6, label: '6時間' },
  { value: 7, label: '7時間' },
  { value: 8, label: '8時間' },
  { value: 9, label: '9時間' },
  { value: 10, label: '10時間' },
  { value: 11, label: '11時間' },
  { value: 12, label: '12時間' },
  { value: 15, label: '15時間' },
  { value: 18, label: '18時間' },
  { value: 24, label: '24時間' }
];

export default class Denki extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gameWatt: 150,
      gameHours: 0,
      workWatt: 15,
      workHours: 3,
      idleWatt: 1.3,
      idleHours: 0,
      otherWatt: 0,
      otherHours: 0,
      codeOff: false,
      formValue: "mobileNote", // 0: note, 1: desktop
    };
    this.bills = {
      day: 0,
      month: 0,
      year: 0,
      fiveYear: 0,
    };
  }

  handleChange = e => {
    if (e.target.id === 'mobileNote') {
      this.setState({
        gameWatt: 150,
        workWatt: 15,
        formValue: e.target.id,
      });
      return;
    }
    if (e.target.id === 'standardNote') {
      this.setState({
        gameWatt: 190,
        workWatt: 25,
        formValue: e.target.id,
      });
      return;
    }
    if (e.target.id === 'desktop') {
      this.setState({
        gameWatt: 210,
        workWatt: 45,
        formValue: e.target.id,
      });
      return;
    }
    if (e.target.id === 'gamingDesktop') {
      this.setState({
        gameWatt: 300,
        workWatt: 65,
        formValue: e.target.id,
      });
      return;
    }
    if (e.target.id === 'codeOff') {
      this.setState({
        codeOff: e.target.checked
      });
      return;
    }
    this.setState({
      [e.target.id]: parseFloat(e.target.value)
    });
  }

  calculate = () => {
    const { gameWatt, gameHours, workWatt, workHours, otherWatt, otherHours, codeOff, formValue } = this.state;
    const sleepWatt = (formValue === "mobileNote" || formValue === "standardNote") ? 0.6 : 1.3;
    const totalHours = gameHours + workHours + otherHours + 0;
    const watt = gameWatt * gameHours + workWatt * workHours + otherWatt * otherHours + sleepWatt*(codeOff ? 0 : 24 - totalHours);
    const bills = {};
    bills.day = Math.round(10 * watt * 0.027)/10;
    bills.month = Math.round(watt * 0.027 * 30);
    bills.year = Math.round(bills.month * 365/30);
    bills.fiveYear = 5 * bills.year;
    return bills;
  }

  render () {
    const bills = this.calculate();
    const { formValue } = this.state;
    const { gameWatt, gameHours, workWatt, workHours, otherWatt, otherHours,codeOff } = this.state;
    return (
      <>
        <SelectBox className="mobile-margin">
          <input type="radio" id="mobileNote" name="notedesktop" value="mobileNote" checked={formValue === "mobileNote"} onChange={this.handleChange} />
          <label htmlFor="mobileNote">モバイルノート</label>
          <input type="radio" id="standardNote" name="notedesktop" value="standardNote" checked={formValue === "standardNote"} onChange={this.handleChange} />
          <label htmlFor="standardNote">スタンダードノート</label>
          <input type="radio" id="desktop" name="notedesktop" value="desktop" checked={formValue === "desktop"} onChange={this.handleChange} />
          <label htmlFor="desktop">デスクトップ</label>
          <input type="radio" id="gamingDesktop" name="notedesktop" value="gamingDesktop" checked={formValue === "gamingDesktop"} onChange={this.handleChange} />
          <label htmlFor="gamingDesktop">ゲーミングデスクトップ</label>
        </SelectBox>
        <div>
          <input type="checkbox" id="codeOff" name="codeOff" checked={codeOff === true} onChange={this.handleChange} />
          <label htmlfor="codeOff">パソコン不使用時は電源を切る。デスクトップは電源コードも抜く</label>
        </div>
        <BasicTable className='center'>
          <thead>
            <tr><th>作業</th><th>消費電力(W)</th><th>作業時間</th></tr>
          </thead>
          <tbody>
            <tr><td>軽作業</td><td><input type="text" id="workWatt" onChange={this.handleChange} value={workWatt} />W</td><td><select  value={workHours} id="workHours" onChange={this.handleChange}>
            { dropDownList.map( (d, i) => <option value={d.value} key={i}>{d.label}</option>)}
          </select></td></tr>
            <tr><td>ゲーム</td><td><input type="text" id="gameWatt" onChange={this.handleChange} value={gameWatt}/>W</td><td><select  id="gameHours" onChange={this.handleChange} value={gameHours}>
            { dropDownList.map( (d, i) => <option value={d.value} key={i}>{d.label}</option>)}
          </select></td></tr>
            <tr><td>その他</td><td><input type="text" id="otherWatt" onChange={this.handleChange} value={otherWatt}/>W</td><td><select  id="otherHours" onChange={this.handleChange} value={otherHours}>
            { dropDownList.map( (d, i) => <option value={d.value} key={i}>{d.label}</option>)}</select></td></tr>
          </tbody>
        </BasicTable>

        <BasicTable className={'center'}>
          <thead>
            <tr><th>期間</th><th>電気代</th></tr>
          </thead>
          <tbody>
            <tr><td>１日</td><td>{bills.day}円</td></tr>
            <tr><td>１ヵ月</td><td>{bills.month}円</td></tr>
            <tr><td>１年</td><td>{bills.year}円</td></tr>
            <tr><td>５年</td><td>{bills.fiveYear}円</td></tr>
          </tbody>
        </BasicTable>
      </>
    );
  }
}